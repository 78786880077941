import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Link,
  NavLink,
  Scripts,
  ScrollRestoration,
  useCatch
} from "remix";

import type { LinksFunction } from "remix";
import type { MetaFunction } from "remix";
import globalStylesUrl from "~/styles/global.css";

export const meta: MetaFunction = () => {
  return { title: "NODΞS - By MS-DDOS & HOOX" };
};

export const links: LinksFunction = () => {
  return [{ rel: "stylesheet", href: "https://cdnjs.cloudflare.com/ajax/libs/foundation/6.7.4/css/foundation.min.css", integrity: "sha512-TgmH0v8FUwmsr3yDgd5PTCgR6lRZ2Q5c7KsUNTHcoxZpOExCX16MYECIL4xdRQOhQlz7pCnZlmA4zda58QWxBw==", crossOrigin: "anonymous", referrerPolicy: "no-referrer"},
  { rel: "stylesheet", href: globalStylesUrl }];
};

function Document({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <Meta />
        {title ? <title>{title}</title> : null}
        <Links />
      </head>
      <body>
        {children}
        <Scripts />
        {process.env.NODE_ENV === "development" && <LiveReload />}
      </body>
    </html>
  );
}

export default function App() {
  return (
    <Document>
      <div className="container">
          <br />
          <div className="grid-x grid-padding-x small-up-2 medium-up-2 large-up-2">
            <div className="cell left bold">
              <h4><strong><Link to="/"><img src="/images/NODES.jpg" className="rounded"/>&nbsp;NODΞS</Link></strong></h4>
            </div>
            <div className="cell right auto">
              <Link to="/about" title="About the team." aria-label="About the team.">about </Link>
              |
              <a href="/NodesWhitepaper.pdf" target="_blank" title="Download the whitepaper." aria-label="Download the whitepaper."> whitepaper </a>
              |
              <Link to="/fuse" title="Fuse NODΞS." aria-label="Fuse NODΞS." preload="intent"> fuse </Link>
              |
              <Link to="/resize" title="Resize NODΞS." aria-label="Resize NODΞS." preload="intent"> resize </Link>
            </div>
          </div>
          <hr />
          <Outlet />
          <hr />
          <div className="grid-x small-up-2 medium-up-2 large-up-4">
            <div className="cell left">
              <a href="https://twitter.com/NodesNFT" target="_blank"><img src="/images/Twitter-Logo-White.svg" height="35" width="35" /></a>
              &nbsp;&nbsp;
              <a href="https://discord.gg/wJxM52AwTT" target="_blank"><img src="/images/Discord-Logo-White.svg" height="35" width="35" /></a>
              &nbsp;&nbsp;
              <a href="https://opensea.io/collection/nodes-onchain" target="_blank"><img src="/images/OpenSea-Logo-White.svg" height="35" width="35" /></a>
              &nbsp;&nbsp;
              <a href="https://etherscan.io/address/0x9e790c3845adae7e5728d71045ae91ebe653baf2" target="_blank"><img src="/images/Etherscan-Logo-White.svg" height="35" width="35" /></a>
            </div>
            <div className="cell right auto">
              <a href="https://twitter.com/MSxDDOS" target="_blank"><img src="/images/DDOS.jpg" className="rounded" /></a>
              &nbsp;&nbsp;
              <a href="https://twitter.com/H00X17" target="_blank"><img src="/images/HOOX.jpg" className="rounded" /></a>
            </div>
          </div>
        </div>
        <ScrollRestoration />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/foundation/6.7.4/js/foundation.min.js" integrity="sha512-+CnEgueYRDzx2s3IIAOpZd8HeOfrSbK3loeHw0jXJODAe3eFROHpBxEsun2kU+sZCaoAA0eFJrhfVV3RGG50GA==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script>
    </Document>
  );
}

export function CatchBoundary() {
  const caught = useCatch();

  return (
    <Document
      title={`${caught.status} ${caught.statusText}`}
    >
      <div className="error-container">
        <h1>
          {caught.status} {caught.statusText}
        </h1>
      </div>
    </Document>
  );
}

export function ErrorBoundary({ error }: { error: Error }) {
  console.error(error);
  return (
    <Document title="Uh-oh!">
      <div className="error-container">
        <h1>App Errorz</h1>
        <pre>{error.message}</pre>
      </div>
    </Document>
  );
}